export const cleanFalsyValues = (obj: Record<string, any>): Record<string, any> => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (![undefined, null, '', NaN].includes(value)) {
      acc[key] = value
    }

    return acc
  }, {})
}

export const deepCopy = (obj: Record<string, any>): Record<string, any> => {
  return JSON.parse(JSON.stringify(obj))
}

export const omit = (obj, key) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [key]: omitted, ...rest } = obj

  return rest
}

export const isObject = (value) => {
  return typeof value === 'object' && value !== null && value.length === undefined
}

export const isArray = (value) => {
  return Array.isArray(value) && value.length > 0
}

export const minMaxByKey = (data: any[], key: string): { min: number, max: number, isEqual: boolean } | null => {
  if (!data.length) return null

  const min = data.reduce((minObj, obj) => obj[key] < minObj[key] ? obj : minObj, data[0])
  const max = data.reduce((maxObj, obj) => obj[key] > maxObj[key] ? obj : maxObj, data[0])

  return { min: min[key], max: max[key], isEqual: min[key] === max[key] }
}

export const toCamelCase = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj.map(toCamelCase)
  } else if (obj !== null && typeof obj === 'object') {
    return Object.keys(obj).reduce((acc, key) => {
      const camelKey = key.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase())
      acc[camelKey] = toCamelCase(obj[key])
      return acc
    }, {} as any)
  }
  return obj
}
